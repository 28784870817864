import {
  axiosSecureInstance,
  axiosSecureInstanceFileUpload,
} from './apiSecure';
import { paramsSerializer } from './serviceHelper.js';

export async function getJobs() {
  let jobs = await axiosSecureInstance.get('mapping_jobs');
  return jobs.data;
}

export async function getJobsHistory() {
  let jobs = await axiosSecureInstance.get('mapping_jobs/history');
  return jobs.data;
}

export async function claimJob(jobId) {
  return await axiosSecureInstance.post(`mapping_jobs/${jobId}/claim_job`);
}

export async function createClinicJob(data) {
  return await axiosSecureInstance.post('mapping_jobs/create_clinic_job', data);
}

export async function getJobDetails(jobId) {
  return await axiosSecureInstance.get(`/mapping_jobs/${jobId}`);
}

export async function deleteJob(jobId) {
  return await axiosSecureInstance.delete(`/mapping_jobs/${jobId}`);
}

export async function updateJobDetails(jobId, updateParams) {
  let response = await axiosSecureInstance.put(
    `/mapping_jobs/${jobId}`,
    updateParams
  );
  return response;
}

export async function createCustomJob(data) {
  return await axiosSecureInstanceFileUpload.postForm(
    'mapping_jobs/create_custom_job',
    data
  );
}

export async function createCustomJobFromFilters(data) {
  return await axiosSecureInstanceFileUpload.postForm(
    'mapping_jobs/create_custom_job_from_filters',
    data
  );
}

export async function completeMapping(jobId) {
  return await axiosSecureInstance.post(
    `/mapping_jobs/${jobId}/complete_mapping`
  );
}

export async function completeJob(jobId) {
  return await axiosSecureInstance.post(`/mapping_jobs/${jobId}/complete`);
}
export async function invoiceJobs(jobIds) {
  let response = await axiosSecureInstance.put('/mapping_jobs/invoice', {
    mapping_job_ids: jobIds,
  });
  return response.data;
}

export async function downloadCompletedJob(jobId) {
  return await axiosSecureInstance.get(
    `/mapping_jobs/${jobId}/download_completed_mappings`
  );
}

export async function unhideJob(jobId) {
  return await axiosSecureInstance.put(
    `/mapping_jobs/${jobId}/unhide_from_mapper`
  );
}

export async function fetchJobCreationEligibility(practiceId, params) {
  const config = {
    params: params,
    paramsSerializer,
  };

  let jobEligibility = await axiosSecureInstance.get(
    `/practices/${practiceId}/fetch_job_creation_eligibility`,
    config
  );

  return jobEligibility.data;
}
